
import { Component,Vue } from 'vue-property-decorator'
import JtPagination from "@/components/JtPagination/index.vue";
import JtDatePicker from "@/components/JtDatePicker/index.vue";
import { loadAuthUsers, removeAuthUser } from "@/api/taoke/tkAuthUser";

@Component({
    name: 'TaobaoAuth',
    components: {
      JtDatePicker,
      JtPagination
    }
})
export default class extends Vue {
  listLoading = true
  total = 0
  list = []
  listQuery = {
    current: 1,
    size: 10,
    keyword: null,
    searchType: null,
    startTime: '',
    endTime: ''
  }

  mounted() {
      this.getList()
  }
  //初始加载数据
  handleFilter() {
    this.listQuery.current = 1
    this.getList()
  }

  //加载api数据
  getList(){
    this.listLoading = true
    loadAuthUsers(this.listQuery).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      })
  }

  /**
   * 删除接口
   */
  handleRemove(userId:number){
    this.$confirm('确定删除当前用户授权信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      removeAuthUser({ userId }).then(response => {
          this.$message({
              type: 'success',
              message: '操作成功!'
            });
          this.getList()
        }).catch(()=>{})
      }).catch(() => {});
  }
}
