import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface saveAppPublisherParam {
    token?:any
}

export interface removeAuthUserParam {
    userId?:any
}

export interface loadAuthUsersParam {
    current?:any
    size?:any
    searchType?:any
    startTime?:any
    endTime?:any
    keyword?:any
}
// -------接口列------

/**
 * [APP]用户端授权并保存私域用户信息
 * @notes 
 */
export function saveAppPublisher(param:saveAppPublisherParam) {
    return get('/taoke/authUser/saveAppPublisher', param);
}

/**
 * [后台]加载所有授权用户信息
 * @notes 
 */
export function loadAuthUsers(param:loadAuthUsersParam) {
    return get('/taoke/authUser/loadAuthUsers', param);
}

/**
 * [后台]删除当前用户授权
 * @notes 
 */
export function removeAuthUser(param:removeAuthUserParam) {
    return get('/taoke/authUser/removeAuthUser', param);
}

